import React from "react";
import IRestServiceOptions from "../../../../../../../model/interface/api/IRestServiceOptions";
import CompanyStructureService from "../../../../../../../model/service/company/CompanyStructureService";
import EmployeesService from "../../../../../../../model/service/company/EmployeesService";
import ScrollContainer from "../../../../../../shared/scrollContainer/ScrollContainer";
import {Spin} from "antd";
import Utils from "../../../../../../../utils";
import {API_FILTER_TYPE} from "../../../../../../../model/constants/ApiConstant";
import {FieldEmployeeOptionsActiveOn} from "../../../../content-type/field/optionEditors/FieldEmployeeOptionsEditor";
import moment, {Moment} from "moment";
import {DATE_FORMAT_YYYY_MM_DD} from "../../../../../../../model/constants/DateConstant";
import IEmployee from "../../../../../../../model/interface/company/IEmployee";
import IRestServiceFilters from "../../../../../../../model/interface/api/IRestServiceFilters";

interface IProps {
    order: string
    onSelect: (item: any) => void
    search: string
    root?: string | string[]
    value?: string | string[]
    onlyDirectChildren?: boolean
    activeOn?: FieldEmployeeOptionsActiveOn
    includeInactive?: boolean
    subordinates?: boolean
    currentEmployee: IEmployee
    presenter?: string
    hideCurrent?: boolean
    filters?: IRestServiceFilters
}

interface IState {
    data: IITem[]
    loading: boolean
    loaded: boolean
}

interface IITem {
    label: string,
    value: string
}

class EmployeeDisplayList extends React.Component<IProps, IState> {
    state = {
        data: [] as IITem[],
        loading: false,
        loaded: false,
        rawData: []
    }

    componentDidMount() {
        this.load().then()
    }

    load(): Promise<void> {
        return Promise.resolve()
            .then(() => new Promise<void>(resolve => {
                this.setState({data: [], loading: true}, resolve)
            }))
            .then(() => this.fetch())
            .then((data) => new Promise<void>(resolve => {
                this.setState({data, loading: false}, resolve)
            }))
    }

    fetch(): Promise<IITem[]> {
        const {root, activeOn, onlyDirectChildren, includeInactive, subordinates, currentEmployee, filters, hideCurrent} = this.props
        let params = {
            filters: {...filters}
        } as IRestServiceOptions
        if (onlyDirectChildren || root) {
            if (onlyDirectChildren) {
                if (root) {
                    params.filters![1] = {
                        type: 'equal',
                        field: 'parent',
                        value: root
                    }
                } else {
                    params.filters![1] = {
                        type: 'isNull',
                        field: 'parent'
                    }
                }
                return CompanyStructureService.loadByParentId(root!).then((results) => {
                    return results
                        .filter(node => node.employee && (includeInactive || node.employee.active) && (!hideCurrent || currentEmployee.uuid !== node.employee.uuid))
                        .map(node => ({value: node.employee?.uuid!, label: node.employee?.fullName!}))
                })
            } else {
                return CompanyStructureService.loadAllChildrenById(root!).then((results) => {
                    return results
                        .filter(node => node.employee && (includeInactive || node.employee.active) && (!hideCurrent || currentEmployee.uuid !== node.employee.uuid))
                        .map(node => ({value: node.employee?.uuid!, label: node.employee?.fullName!}))
                })
            }
        } else {
            params.filters!.parent = {
                type: API_FILTER_TYPE.IS_NULL,
                field: 'parent'
            }
            if (activeOn?.type) {
                let date: Moment
                switch (activeOn.type) {
                    case 'day':
                        date = activeOn.value && activeOn.value > moment().date() ?
                            Utils.previousMonth().date(activeOn.value - 1 || 28)
                            : moment().date(activeOn.value || 28)
                        break;
                    case 'month':
                        date = activeOn.value && activeOn.value > moment().month() + 1 ?
                            moment().month((activeOn.value || 12) - 1).year(moment().year() - 1).endOf('month')
                            : moment().month((activeOn.value || 12) - 1).endOf('month')
                        break;
                    case 'year':
                        date = moment().endOf('year')
                        break;
                }

                params.filters!.activeTo = {
                    type: API_FILTER_TYPE.OR,
                    children: {
                        1: {
                            type: API_FILTER_TYPE.GREATER_OR_EQUAL,
                            field: 'terminationDate',
                            value: date.format(DATE_FORMAT_YYYY_MM_DD)
                        },
                        2: {
                            type: API_FILTER_TYPE.IS_NULL,
                            field: 'terminationDate',
                        }
                    }
                }
                params.filters!.activeFrom = {
                    type: API_FILTER_TYPE.OR,
                    children: {
                        1: {
                            type: API_FILTER_TYPE.LESSER_OR_EQUAL,
                            field: 'beginDate',
                            value: date.format(DATE_FORMAT_YYYY_MM_DD)
                        },
                        2: {
                            type: API_FILTER_TYPE.IS_NULL,
                            field: 'beginDate',
                        }
                    }
                }
            } else if (!includeInactive) {
                params.filters!.active = {
                    type: API_FILTER_TYPE.EQUAL,
                    field: 'active',
                    value: true
                }
            }
            if (hideCurrent && currentEmployee){
                params.filters!.self = {
                    field: "uuid",
                    type: API_FILTER_TYPE.NOT_EQUAL,
                    value: currentEmployee.uuid
                }
            }

            if (subordinates) {
                return EmployeesService.getInstance().subordinatesChoiceList(this.getPresenter(), currentEmployee, {
                    ...params,
                    allUserEmployees: !hideCurrent
                }).then(({results}) => {
                    return Object.keys(results).map(value => ({value, label: results[value]}))
                })
            }
            return EmployeesService.getInstance().choiceList(this.getPresenter(), params).then(({results}) => {
                return Object.keys(results).map(value => ({value, label: results[value]}))
            })
        }
    }

    getPresenter() {
        if (this.props.presenter) {
            return this.props.presenter
        } else {
            return EmployeesService.getInstance().getDefaultPresenter().name
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.props.root !== prevProps.root || this.props.subordinates !== prevProps.subordinates) {
            this.load().then()
        }
    }

    getFilteredData() {
        const {search} = this.props
        const orderMark = this.props.order === 'ASC' ? 1 : -1
        // filter unique data
        return this.state.data
            .filter(item => !search || Utils.stringContains(item.label, search))
            // .sort((a,b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase(), 'cs') ? orderMark : -orderMark )
            .sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? orderMark : -orderMark)
            .filter((item, index, self) => Utils.findIndex(self, {value: item.value}) === index)
    }

    render() {
        const data = this.getFilteredData()
        const {loading} = this.state
        const {value} = this.props

        return (
            <ScrollContainer visibility={"visible"} style={{
                maxHeight: 300,
                overflow: "auto"
            }}>
                {data.length > 0 ? (
                    <>
                        {data.map(item => (
                            <div
                                key={item.value}
                                className={"px-2 py-1 hover-background" + ((Array.isArray(value) && value?.includes(item.value)) || value === item.value ? ' bg-gray-lighter' : '')}
                                style={{cursor: "pointer"}}
                                onClick={() => this.props.onSelect(item)}>{item.label}</div>
                        ))}
                    </>
                ) : (
                    loading ? <Spin size={"small"} spinning={loading}/> :
                        <span className={"text-muted"}>
                        {this.props.search.length > 0 ? (<>Pro výraz "{this.props.search}" nejsou žádné
                            výsledky</>) : <>Žádné výsledky</>}
                    </span>
                )}
            </ScrollContainer>
        )
    }
}

export default EmployeeDisplayList