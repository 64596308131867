import {Button, Card, Col, Dropdown, Form, Input, Row, Select} from 'antd';
import React from "react";
import IFieldOptions from "../../../../../../model/interface/form/elementOptions/IFieldOptions";
import {debounce} from "underscore";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../../../redux/selectors";
import IRepositoryService from "../../../../../../model/interface/IRepositoryService";
import {
    ApartmentOutlined,
    BarsOutlined,
    CloseOutlined,
    FilterOutlined,
    SortAscendingOutlined,
    SortDescendingOutlined
} from "@ant-design/icons";
import ButtonGroup from "antd/es/button/button-group";
import EmployeeDisplayList from "./display/EmployeeDisplayList";
import EmployeesService from "../../../../../../model/service/company/EmployeesService";
import IEmployee from "../../../../../../model/interface/company/IEmployee";
import Utils from "../../../../../../utils";
import CompanyStructureService from "../../../../../../model/service/company/CompanyStructureService";
import FormFieldCompanyStructure from "./FormFieldCompanyStructure";
import {ISetupState} from "../../../../../../redux/reducers/Setup";
import IUser from "../../../../../../model/interface/security/IUser";
import ILabelValue from "../../../../../../model/interface/util/ILabelValue";

interface IProps {
    value?: IEmployee | string | (string | IEmployee)[],
    onChange?: (key?: string | string[]) => void
    options: IFieldOptions
    findServiceByClassName: (name: string) => IRepositoryService
    user: IUser
}

interface IState {
    data: { [x: string]: string },
    loading: boolean
    options: IFieldOptions
    visible: boolean
    filterVisible: boolean
    order: "ASC" | "DESC"
    displayType: 'list' | 'tree'
    search: string
    selected: ILabelValue[]
    units?: { value: string, label: string }[]
    filteredUnit?: string
}

class FormFieldEmployee extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            data: {},
            loading: false,
            visible: false,
            options: props.options,
            order: "ASC",
            displayType: 'list',
            search: '',
            filterVisible: false,
            selected: []
        };
        this.onSearch = debounce(this.onSearch, 300);
        this.searchInput = React.createRef();
    }

    isUnmounted = false;

    searchInput: React.RefObject<Input>

    componentDidMount() {
        this.loadSelected().then()
    }

    componentWillUnmount() {
        this.isUnmounted = true;
    }

    loadSelected(): Promise<void> {
        return Promise.resolve()
            .then(() => new Promise<void>(resolve => this.setState({loading: true}, resolve)))
            .then(() => this.fetchSelected())
            .then(values => new Promise(resolve => this.setState({
                loading: false,
                selected: values
            }, resolve)))
    }

    isEmployeeArray = (array?: (IEmployee | string)[]): array is IEmployee[] => typeof array?.[0] === 'object'

    fetchSelected(): Promise<ILabelValue[]> {
        const {options, value} = this.props
        let selected: (IEmployee | string)[]
        const presenter = options.contentTypePresenter || 'default'
        if (Array.isArray(value)) {
            selected = value
        } else {
            selected = value ? [value] : []
        }
        const ids = Utils.parseObjectToIdentifier(selected)
        if (!ids) {
            return Promise.resolve([])
        }
        if (ids.length > 0) {
            return this.isEmployeeArray(selected) ? Promise.resolve(selected.map(e => ({
                value: e.uuid,
                label: e._presenters?.[presenter] || ''
            }))) : EmployeesService.getInstance().choiceList(options.contentTypePresenter || 'default', {
                filters: {
                    0: {
                        type: 'in',
                        field: typeof ids[0] === "number" ? 'id' : 'uuid',
                        value: ids
                    }
                }
            }).then(response => {
                return Object.entries(response.results).map(([value, label]) => ({value, label}))
            })
        }
        return Promise.resolve([])
    }

    isMultiple() {
        return this.props.options.employeeMultiple
    }

    toggleFilterVisible = () => {
        this.setState(state => ({filterVisible: !state.filterVisible}))
    }

    onSelect = (option?: any) => {
        const allowUnselect = this.props.options.employeeAllowUnselect === undefined ? true : this.props.options.employeeAllowUnselect
        let visible = true
        let selected = this.state.selected
        let value = Utils.parseObjectToIdentifier(this.props.value, 'uuid')
        if (this.isMultiple()) {
            if (!Array.isArray(value)) {
                value = []
            }
            let index = value.indexOf(option?.value)
            if (index >= 0) {
                value.splice(index, 1)
                let index2 = selected.findIndex(s => s.value === option?.value)
                selected.splice(index2, 1)
            } else {
                value.push(option?.value)
                selected.push({value: option?.value, label: option.label})
            }
        } else {
            selected = value === option?.value && allowUnselect ? [] : option?.label ? [{
                value: option?.value,
                label: option.label
            }] : []
            value = value === option?.value && allowUnselect ? undefined : option?.value
            visible = false
        }
        (this.props.value !== value || allowUnselect) && this.setState(() => ({
            selected,
            visible,
            loading: false,
        }), () => this.updateParent(value))

    };

    updateParent = (value?: string | string[]) => {
        this.props.onChange?.(value)
    }

    onSearch(search: string) {
        this.setState({search})
    }

    toggleVisible = () => {
        this.setState({visible: !this.state.visible}, () => {
            setTimeout(() => this.state.visible && this.searchInput.current?.focus(), 1) //have to wait
        })
    }

    setSortOrder(order: "ASC" | "DESC") {
        this.setState({order})
    }

    setDisplayType(displayType: 'list' | 'tree') {
        this.setState({displayType})
    }

    onShowFilter = (visible: boolean) => {
        const {options} = this.props
        if (visible && !this.state.units) {
            const structureRoots = typeof options.employeeStructureRoot! === 'object' ? options.employeeStructureRoot! : [options.employeeStructureRoot!]
            structureRoots.forEach((root: string) => {
                CompanyStructureService.loadAllStructureChoicesById(root, 'unit').then((results) => {
                    this.setState({
                        units: Object.entries(results).map(([key, value]) => ({value: key, label: value}))
                    })
                })
            })
        }
    }

    onFilterChange = (value?: string) => {
        this.setState({filteredUnit: value}, this.toggleFilterVisible)
    }

    onChangeValue = (change: any) => {
        const {value} = this.props
        const parsedValue = Utils.parseObjectToIdentifier(value, 'uuid')
        if (this.isMultiple() && Array.isArray(value)) {
            const index = parsedValue.findIndex((i: string) => !change.includes(i))
            if (index > -1) {
                parsedValue?.splice(index, 1)
                this.setState({}, () => this.updateParent(parsedValue))
            }
        } else {
            this.updateParent(parsedValue)
        }
    }

    render() {
        const {options, value, user} = this.props
        const {displayType, order, search, selected, visible, loading, units, filterVisible, filteredUnit} = this.state
        const parsedValue = Utils.parseObjectToIdentifier(value, 'uuid')
        let selectedValues: ILabelValue[] = []
        if (Array.isArray(parsedValue)) {
            selectedValues = selected.filter(s => parsedValue.includes(s.value))
        } else if (value) {
            selectedValues = selected.filter(s => parsedValue === s.value)
        }

        return (
            <Select className={'w-100'} placeholder={options.placeholder} allowClear={options.showClear}
                    disabled={options.disabled}
                    loading={loading} onClear={() => this.onSelect()}
                    open={visible}
                    mode={options.employeeMultiple ? 'multiple' : undefined}
                    onChange={(selected: any) => this.onChangeValue(selected)}
                    dropdownMatchSelectWidth={false}
                    onDropdownVisibleChange={this.toggleVisible}
                    value={parsedValue} dropdownRender={() => (
                <Card title="Výběr zaměstnance" size={"small"} bordered={false} className={'m-0'}
                      extra={<Button type={'link'} danger icon={<CloseOutlined/>} size={"small"}
                                     onClick={this.toggleVisible} className={"font-size-sm"}></Button>}>
                    <Row className={"mb-2"} gutter={[6, 6]}>
                        {!options.employeeDisableTree && (
                            <Col>
                                <ButtonGroup className={"pr-2"}>
                                    <Button size={'small'} icon={<BarsOutlined/>}
                                            type={displayType === 'list' ? 'primary' : 'default'}
                                            onClick={() => this.setDisplayType('list')}/>
                                    <Button size={'small'} icon={<ApartmentOutlined/>}
                                            type={displayType === 'tree' ? 'primary' : 'default'}
                                            onClick={() => this.setDisplayType('tree')}/>
                                </ButtonGroup>
                            </Col>
                        )}
                        <Col>
                            <ButtonGroup className={"pr-2"}>
                                <Button size={'small'} icon={<SortAscendingOutlined/>}
                                        type={order === 'ASC' ? 'primary' : 'default'}
                                        onClick={() => this.setSortOrder('ASC')}/>
                                <Button size={'small'} icon={<SortDescendingOutlined/>}
                                        type={order === 'DESC' ? 'primary' : 'default'}
                                        onClick={() => this.setSortOrder('DESC')}/>
                            </ButtonGroup>
                        </Col>
                        <Col>
                            <Dropdown placement={"bottomCenter"} visible={filterVisible}
                                      onVisibleChange={this.onShowFilter}
                                      overlay={
                                          <Card className={'p-2'}>
                                              <Form.Item label={'Jednotka'} className={'m-0'}>
                                                  <Select loading={!units} dropdownMatchSelectWidth={false}
                                                          allowClear={true}
                                                          onChange={this.onFilterChange}>
                                                      {units?.map(unit => (
                                                          <Select.Option value={unit.value} onChange
                                                                         key={unit.value}>{unit.label}</Select.Option>
                                                      ))}
                                                  </Select>
                                              </Form.Item>
                                          </Card>
                                      }>
                                <Button type={filteredUnit ? "primary" : 'default'} onClick={this.toggleFilterVisible}
                                        className={'mr-2'} style={{zIndex: 1051}}
                                        size={"small"} icon={<FilterOutlined/>}></Button>
                            </Dropdown>
                        </Col>
                        <Col className={'flex-grow-1'}>
                            <Input.Search size={"small"} style={{minWidth: 200}} className={"pr-2"}
                                          onChange={(event) => this.onSearch(event.target.value)}
                                          defaultValue={search} ref={this.searchInput}/>
                        </Col>
                    </Row>
                    <div hidden={displayType !== 'list'}>
                        <EmployeeDisplayList
                            onSelect={(item) => this.onSelect(item)}
                            search={search}
                            value={parsedValue}
                            order={order}
                            onlyDirectChildren={options.employeeOnlyDirectChildren}
                            root={filteredUnit || options.employeeStructureRoot}
                            activeOn={options.employeeActiveOn}
                            includeInactive={options.employeeIncludeInactive}
                            subordinates={options.employeeSubordinates}
                            currentEmployee={user.employees[0]}
                            presenter={options.contentTypePresenter}
                            filters={options.customFilters}
                            hideCurrent={options.employeeHideCurrent}
                        />
                    </div>
                    <div hidden={displayType !== 'tree'}>
                        <FormFieldCompanyStructure
                            onChange={(node: any) => this.onSelect({
                                value: node.employee!.uuid,
                                label: node.employee!.fullName
                            })} // we use onChangeNode instead
                            options={{
                                type: 'companyStructure',
                                label: options.label,
                                companyStructureAccepts: "employee",
                                companyStructureMultiple: this.isMultiple(),
                                companyStructureRoot: filteredUnit || options.employeeStructureRoot,
                                companyStructureOnlyDirectChildren: options.employeeOnlyDirectChildren,
                                companyStructureHideCurrent: options.employeeHideCurrent,
                                companyStructureSearch: search,
                                companyStructureOrder: order,
                                companyReturnNode: true
                            }}
                            currentEmployee={user.employees[0]}
                            value={parsedValue}
                        />
                    </div>

                </Card>
            )}>
                {selectedValues.map((option, index) => (
                    <Select.Option value={option.value} key={index}>
                        {option.label}
                    </Select.Option>
                ))}
            </Select>
        )
    }
}

const mapStateToProps = (state: RootStateOrAny) => {
    const {user} = state.setup as ISetupState
    return {
        user,
        findServiceByClassName: (name: string) => selectors.services.findOneByFullClassName(state, name),
    }
}
export default connect(mapStateToProps)(FormFieldEmployee)